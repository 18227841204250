import React, { Component } from "react";
import "../assets/main.css";

class ImageCard extends Component {
    state = {};
    constructor(props) {
        super(props);
        this.data = this.props.dataFromParent
    }

    render() {
        return (
            <a href={this.data.hostPageUrl} className="img-container">
                <img src={this.data.thumbnailUrl} alt={this.data.name}/>
                <div className="img-description">
                    <span style={{color: '#fff', textAlign: 'center', display: 'inline-block', verticalAlign: 'middle', padding: 5, fontSize: 10}}>{this.data.name}{'\n'}</span>
                    <a href={this.data.hostPageUrl} style={{color: '#fff',display: 'inline-block', verticalAlign: 'middle', padding: 5, textDecoration: 'none', fontSize: 6}}>{this.data.hostPageUrl}</a>
                </div>
            </a>
        );
    }
}
export default ImageCard;

import React, { Component } from "react";
import "./assets/main.css";
import SearchBar from "./utils/SearchBar";
import {Container, Col, Row} from 'react-bootstrap'
import {storeData, clearData} from './utils/resultHandler'
import SearchIcon from '@material-ui/icons/SearchOutlined';
import saveToMongo from "./utils/savetoMongo";

class Home extends Component {

    constructor(props) {
        super(props)
        clearData()

        this.state = {
            searchText: ""
        }
    }

    webSearch = async (page) => {
        console.log("Web search ===> ", this.state.searchText);
        let apiURL = "https://api.bing.microsoft.com/";
        let headers = { "Ocp-Apim-Subscription-Key": 'ab553acd4424436096d4ef3b183b46ec' };
        let searchString = encodeURIComponent(this.state.searchText);
        if (this.state.searchText) {
          saveToMongo(this.state.searchText);
          fetch(apiURL + "v7.0/search?responseFilter=webpages&q=" + searchString+"&offset="+((page - 1) * 10), {
            headers,
          }).then((res) => {
            res.json().then((json) => {
                // console.log("Web results => ", JSON.stringify(json.webPages.value))
                // store type of results, result, current page
    
                let webResults = json.webPages.value
                let resultType = "web"
                let currentpage = page
    
                // storeData()
                storeData(webResults, resultType, currentpage, this.state.searchText)
    
                // redirect to the new page to display the results
                window.location = '/search'
            });
          });
        }
      };

    render() {
        return(
            <Container>
                <Row>
                    <Col style={{marginTop: '10%'}}>
                        <div className="logo">
                            <a href="/">
                                <img src={require('./assets/logo.png').default} alt="logo" />
                            </a>
                        </div>
                        <div className="search-container">
                            <SearchBar />
                        </div>
                        <a onClick={() => {
                                    // this.type = "news"
                                    this.webSearch(1)
                                }}><div className={"main-s-button"}>
                                <SearchIcon />
                                <button type="button"  style={{outline: 'none', border: 'none', background: 'transparent'}}>
                                    Search
                                </button>
                            </div></a>
                    </Col>
                </Row>
            </Container>
            // <div className="body-container">
            //     <div className="row logo-home-container">
            //         <div className="col-md-12">
            //             <div className="logo">
            //                 <a href="/">
            //                     <img src={require('./assets/logo.png').default} alt="logo" />
            //                 </a>
            //             </div>
            //         </div>
            //     </div>
            //     <div className="search-container">
            //         <SearchBar />
            //     </div>
            // </div>
        )
    }
}

export default Home

import React, { Component } from "react";
import SearchBar from "./utils/SearchBar";
import "./assets/main.css";
import {Container, Col, Row} from 'react-bootstrap'
import {storeData, getData} from './utils/resultHandler'
import ResultCard from "./utils/ResultCard";
import NewsCard from "./utils/NewsCard";
import saveToMongo from "./utils/savetoMongo";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import ImageIcon from '@material-ui/icons/ImageOutlined'
import NewsIcon from '@material-ui/icons/AnnouncementOutlined'
import VideoIcon from '@material-ui/icons/Videocam'
import VideoCard from './utils/VideoCard'
import ImageCard from './utils/ImageCard'

class Search extends Component {

    constructor(props) {
        super(props)

        let [ result, type, page, query ] = getData()
        this.result = result
        this.type = type
        this.page = page
        this.searchText = query
        // console.log("type => ", this.searchText)


        let minPage = (this.page - 2 > 0) ? this.page - 2 : 1;
        // console.log("minPage ===> ", minPage, typeof(minPage))
        this.paginationArray = [];
        for (let i = 0; i < 5; i++) {
            this.paginationArray.push(minPage + i);
        }

        this.state = {
            searchText: this.searchText
        }
    }

    paginationSearch(page) {
        if(this.type === "web") {
            this.webSearch(page)
        } else if(this.type === "image") {
            this.imageSearch(page)
        } else if(this.type === "news") {
            this.newsSearch(page)
        } else if(this.type === "video") {
            this.videoSearch(page)
        }
    }

    webSearch = async (page) => {
        console.log("Web search ===> ", this.state.searchText);
        let apiURL = "https://api.bing.microsoft.com/";
        let headers = { "Ocp-Apim-Subscription-Key": 'ab553acd4424436096d4ef3b183b46ec' };
        let searchString = encodeURIComponent(this.state.searchText);
        if (this.state.searchText) {
          saveToMongo(this.state.searchText);
          fetch(apiURL + "v7.0/search?responseFilter=webpages&q=" + searchString+"&offset="+((page - 1) * 10), {
            headers,
          }).then((res) => {
            res.json().then((json) => {
                // console.log("Web results => ", JSON.stringify(json.webPages.value))
                // store type of results, result, current page
    
                let webResults = json.webPages.value
                let resultType = "web"
                let currentpage = page
    
                // storeData()
                storeData(webResults, resultType, currentpage, this.state.searchText)
    
                // redirect to the new page to display the results
                window.location = '/search'
            });
          });
        }
      };

    newsSearch = async (page) => {
        console.log("News search ===> ", this.state.searchText) 
        let apiURL = "https://bing-news-search1.p.rapidapi.com/news/search"+"?q="+encodeURIComponent(this.state.searchText)+"&safeSearch=Off&textFormat=Raw&freshness=Day&offset=" + ((page - 1) * 10);
        let headers = {
            'x-bingapis-sdk': 'true',
            'x-rapidapi-key': '3fe9872e94mshf148b0071798372p1b8684jsndae02e4e3799',
            'x-rapidapi-host': 'bing-news-search1.p.rapidapi.com'
        }
        // let searchString = encodeURIComponent(this.state.searchText);
        if (this.state.searchText) {
            saveToMongo(this.state.searchText);
            fetch(apiURL, { headers})
            .then((res) => {
                res.json().then((json) => {
                // console.log("News results => ", JSON.stringify(json.value))
                  // store type of results, result, current page
      
                  let newsResults = json.value
                  let resultType = "news"
                  let currentpage = page
      
                  // storeData()
                  storeData(newsResults, resultType, currentpage, this.state.searchText)
      
                  // redirect to the new page to display the results
                  window.location = '/search'
              });
            });
        }
    }

    imageSearch = async (page) => {
        console.log("Image search ===> ", this.state.searchText);
        let apiURL = "https://bing-image-search1.p.rapidapi.com/images/search" + '?q='+ encodeURIComponent(this.state.searchText) +'&offset=' + ((page - 1) * 10);
        let headers = {
        'x-rapidapi-key': '3fe9872e94mshf148b0071798372p1b8684jsndae02e4e3799',
        'x-rapidapi-host': 'bing-image-search1.p.rapidapi.com'
      }
    //     let searchString = encodeURIComponent(this.state.searchText);
        if (this.state.searchText) {
          saveToMongo(this.state.searchText);
          fetch(apiURL, {
            headers,
          }).then((res) => {
            res.json().then((json) => {
                // console.log("Web results => ", JSON.stringify(json.value))
                // store type of results, result, current page
    
                let imageResults = json.value
                let resultType = "image"
                let currentpage = page
    
                // storeData()
                storeData(imageResults, resultType, currentpage, this.state.searchText)
    
                // redirect to the new page to display the results
                window.location = '/search'
            });
          });
        }
      };

    videoSearch = async (page) => {
        console.log("Video Search ===> ", this.state.searchText)
        let apiURL = "https://bing-video-search1.p.rapidapi.com/videos/search?q="+ encodeURIComponent(this.state.searchText) +'&offset=' + ((page - 1) * 10);

        let headers = {
            'x-rapidapi-key': '3fe9872e94mshf148b0071798372p1b8684jsndae02e4e3799',
            'x-rapidapi-host': 'bing-video-search1.p.rapidapi.com'
        }

        if (this.state.searchText) {
            saveToMongo(this.state.searchText);
            fetch(apiURL, {
              headers,
            }).then((res) => {
              res.json().then((json) => {
                  // console.log("Web results => ", JSON.stringify(json.value))
                  // store type of results, result, current page
      
                  let imageResults = json.value
                  let resultType = "video"
                  let currentpage = page
      
                  // storeData()
                  storeData(imageResults, resultType, currentpage, this.state.searchText)
      
                  // redirect to the new page to display the results
                  window.location = '/search'
              });
            });
        }
    }

    render() {
        // console.log("Search text => ", this.state.searchText)
        // console.log("This page is => ", this.page, typeof(this.page))
        let details = ""
        if(this.type === "web") {
            details = this.result.map(result => (
                <ResultCard dataFromParent={result}/>
            ))
        } else if(this.type === "news") {
            details = this.result.map(result => (
                <NewsCard dataFromParent={result} />
            ))
        } else if(this.type === "image") {
        //     details = <ImageList rowHeight={200} className="imageList" cols={4} gap={24}>
        //     {this.result.map((item) => (
        //       <ImageListItem key={item.imageInsightsToken} className="image-item">
        //         <img src={item.contentUrl} alt={item.name} />
        //         <ImageListItemBar
        //           title={item.name}
        //           subtitle={<a target="_blank" rel="noopener noreferrer" href={item.hostPageUrl} style={{color: 'inherit'}}>{item.hostPageDisplayUrl}</a>}
        //         //   actionIcon={
        //         //     <IconButton aria-label={`info about ${item.title}`}>
        //         //       <InfoIcon />
        //         //     </IconButton>
        //         //   }
        //         />
        //       </ImageListItem>
        //     ))}
        //   </ImageList>
            details = this.result.map(result => (
                <ImageCard dataFromParent={result} />
            ))
        } else if(this.type === "video") {
            details = this.result.map(result => (
                <VideoCard dataFromParent={result} />
            ))
        }
        return(
            <Container>
                <Row>
                    <Col xs={12} lg={2}>
                        <div className="logo" style={{ margin: '3vh auto 0 auto' }}>
                            <a href="/"><img src={require('./assets/logo.png').default} alt="logo" /></a>
                        </div>
                    </Col>
                    <Col xs={12} lg={7} sm={12}>
                        <div className="search-page-bar-container">
                            <SearchBar />
                        </div>
                        <div style={{marginBottom: '3vh', display: 'flex', flexDirection:'row'}}>
                            {/* <Col> */}
                            <a onClick={() => {
                                    // this.type = "web"
                                    this.webSearch(1)
                                }}><div className={this.type === "web" ? "s-button-active" : "s-button"} >
                                <SearchIcon />
                                <button type="button"  style={{outline: 'none', border: 'none', background: 'transparent'}}>
                                    Web
                                </button>
                            </div></a>
                            {/* </Col> */}
                            {/* <Col> */}
                            <a onClick={() => {
                                    // this.type = "image"
                                    this.imageSearch(1)
                                }}><div className={this.type === "image" ? "s-button-active" : "s-button"}>
                                <ImageIcon />
                                <button type="button"  style={{outline: 'none', border: 'none', background: 'transparent'}}>
                                    Image
                                </button>
                            </div></a>
                            {/* </Col> */}
                            {/* <Col> */}
                            <a onClick={() => {
                                    // this.type = "news"
                                    this.newsSearch(1)
                                }}><div className={this.type === "news" ? "s-button-active" : "s-button"}>
                                <NewsIcon />
                                <button type="button"  style={{outline: 'none', border: 'none', background: 'transparent'}}>
                                    News
                                </button>
                            </div></a>
                            {/* </Col> */}
                            <a onClick={() => {
                                    // this.type = "news"
                                    this.videoSearch(1)
                                }}><div className={this.type === "video" ? "s-button-active" : "s-button"}>
                                <VideoIcon />
                                <button type="button"  style={{outline: 'none', border: 'none', background: 'transparent'}}>
                                    Videos
                                </button>
                            </div></a>
                        </div>
                        <Row>
                            {
                                details
                            }
                        </Row>
                    </Col>
                </Row>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <div className="results-footer">
                        <div className="pagination-container">
                            {
                            this.page > 3 ?
                                <span onClick={() => this.paginationSearch(this.paginationArray[0] - 1)}>{"<<"}</span> : null
                            }
                            {
                            this.paginationArray.map(number => (
                                <span onClick={() => this.paginationSearch(number)} className={this.page === number ? "currentPage" : ""}>{number}</span>
                            ))
                            }
                            <span onClick={() => this.paginationSearch(this.paginationArray[4] + 1)}>{">>"}</span>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="logo-footer">
              <a href="/"><img src={require('./assets/logo.png').default} alt="logo" /></a>
            </div>
          </div>
        </div>
            </Container>
        )
    }
}

export default Search
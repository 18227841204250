const storeData = (result, type, page, query) => {
    localStorage.setItem('searchResults', JSON.stringify(result))
    localStorage.setItem("searchResultsType", type)
    localStorage.setItem("searchedPage", page)
    localStorage.setItem("searchText", query)
}

const getData = () => {
    return [
        JSON.parse(localStorage.getItem('searchResults')),
        localStorage.getItem("searchResultsType"),
        parseInt(localStorage.getItem("searchedPage")),
        localStorage.getItem("searchText")
    ]
}

const clearData = () => {
    localStorage.removeItem('searchResults')
    localStorage.removeItem("searchResultsType")
    localStorage.removeItem("searchedPage")
    localStorage.removeItem("searchText")
}

export {
    storeData,
    getData,
    clearData
}
import React, { Component } from "react";
import "../assets/main.css";

class VideoCard extends Component {
    state = {};
    constructor(props) {
        super(props);
        this.data = this.props.dataFromParent;
        // console.log("Video data => ", this.data.motionThumbnailUrl)
    }

    render() {
        return (
            // <div className="result-card">
            //     <div>
            //         <a className="link-text" href={this.data.url}>{this.data.name.replace(/<[^>]*>?/gm, '')}</a>
            //     </div>
            //     <div>
            //         <a target="_blank" rel="noopener noreferrer" className="result-title" href={this.data.url}>{this.data.displayUrl.replace(/<[^>]*>?/gm, '')}</a>
            //     </div>
            //     <div>
            //         <span className="result-description">{this.data.snippet.replace(/<[^>]*>?/gm, '')}</span>
            //     </div>
            // </div>
            <div class="flex-container">
                <div class="video-image">
                    <img src={this.data.thumbnailUrl} alt={this.data.name} />
                </div>
                <div>
                    <div>
                        <a className="link-text-video" href={this.data.hostPageUrl}>{this.data.name.replace(/<[^>]*>?/gm, '')}</a>
                    </div>
                    <div>
                        <a target="_blank" rel="noopener noreferrer" className="result-title" href={this.data.hostPageUrl}>{this.data.hostPageUrl.replace(/<[^>]*>?/gm, '')}</a>
                    </div>
                    <div>
                        {/* <span className="result-description">{this.data.description.replace(/<[^>]*>?/gm, '')}</span> */}
                    </div>
                </div>
            </div>
        );
    }
}
export default VideoCard;

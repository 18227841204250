const saveToMongo = (searchText) => {

    const data = { "searchString": searchText };
    fetch("https://dufodufo.com:4443", {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(data),
        headers: {
        "content-type": "application/json",
        },

    })
    .then((res) => {
        console.log(res);
    })
    .catch((err) => {
        console.log(err);
    });
}

export default saveToMongo
import React, { Component } from "react";
import "../assets/main.css";

class NewsCard extends Component {
    state = {};
    constructor(props) {
        super(props);
        this.data = this.props.dataFromParent;
    }

    render() {
        return (
            <div className="result-card">
                <div>
                    <a className="link-text" href={this.data.url}>{this.data.name.replace(/<[^>]*>?/gm, '')}</a>
                </div>
                <div>
                    <a target="_blank" rel="noopener noreferrer" className="result-title" href={this.data.url}>{this.data.url.replace(/<[^>]*>?/gm, '')}</a>
                </div>
                <div>
                    <span className="result-description">{this.data.description.replace(/<[^>]*>?/gm, '')}</span>
                </div>
            </div>
        );
    }
}
export default NewsCard;

import React, { Component } from "react";
import "../assets/main.css";
import {
  Grid,
  Input,
  FormControl,
//   TextField
} from "@material-ui/core";
import saveToMongo from "./savetoMongo";
import { storeData } from './resultHandler'
import SearchIcon from '@material-ui/icons/SearchOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

// import { Container } from "react-bootstrap";
// import { browserHistory } from "react-router";
// import { dataService } from "./dataService";
// import { apiKey } from "../assets/apiKey";

// function saveToMongo(searchText) {
//   const data = { "searchString": searchText };
//   fetch("https://dufodufo.com:4443", {
//     method: "POST",
//     mode: "cors",
//     body: JSON.stringify(data),
//     headers: {
//       "content-type": "application/json",
//     },
    
//   })
//     .then((res) => {
//       console.log(res);
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// }

class SearchBar extends Component {
  state = {
    searchText: localStorage.getItem('searchText') ? localStorage.getItem('searchText'): "",
    collapse: false,
    searchButton: true,
    activeSuggestion: 0,
    filteredSuggestions: [],
    showSuggestions: false,
  };

  constructor(props) {
    super(props);

    this.fetchSuggestions = this.fetchSuggestions.bind(this)
    // this.search = this.search.bind(this);
    // let params = new URLSearchParams(
    //   browserHistory.getCurrentLocation().search
    // ).get("searchText");
    // console.log("Params at searchBar => ", params)
    // this.searchText = params ? params.split("=")[0].split("?")[0] : null;
    // this.state.searchText = this.searchText;
    // this.setInput = this.setInput.bind(this)
  }

  handleKeyPress = (e) => {
    console.log("Key pressed => ", e.key)
    if (e.key === "Enter") {
      this.webSearch(1);
    }
  };

  fetchSuggestions(query) {
    fetch('https://api.bing.microsoft.com/v7.0/suggestions?q='+ encodeURIComponent(query), {
        headers: {
            'Ocp-Apim-Subscription-Key': 'ab553acd4424436096d4ef3b183b46ec'
        }, 
        method: 'GET'
    })
    .then(res => res.json())
    .then(response => {
        // console.log("Suggestions are ===> ", JSON.stringify(response.suggestionGroups[0].searchSuggestions))
        let suggestions = response.suggestionGroups[0].searchSuggestions
        let filteredSuggestions = []
        suggestions.map(item => {
            filteredSuggestions.push({"displayText": item.displayText, query: item.query})
        })
        if(filteredSuggestions.length === 0) {
            this.setState({
                showSuggestions: false
            })
        }
        else {
            this.setState({
                filteredSuggestions: filteredSuggestions,
                showSuggestions: true
            })
        }
    })
  }

  handleTextChange = (e) => {
    this.setState({ searchText: e.target.value });
      this.fetchSuggestions(e.target.value)
  };

  webSearch = async (page) => {
    console.log("Web search ===> ", this.state.searchText);
    let apiURL = "https://api.bing.microsoft.com/";
    let headers = { "Ocp-Apim-Subscription-Key": 'ab553acd4424436096d4ef3b183b46ec' };
    let searchString = encodeURIComponent(this.state.searchText);
    if (this.state.searchText) {
      saveToMongo(this.state.searchText);
      fetch(apiURL + "v7.0/search?responseFilter=webpages&q=" + searchString+"&offset="+((page - 1) * 10), {
        headers,
      }).then((res) => {
        res.json().then((json) => {
            // console.log("Web results => ", JSON.stringify(json.webPages.value))
            // store type of results, result, current page

            let webResults = json.webPages.value
            let resultType = "web"
            let currentpage = page

            // storeData()
            storeData(webResults, resultType, currentpage, this.state.searchText)

            // redirect to the new page to display the results
            window.location = '/search'
        });
      });
    }
  };

//   imagesearch = () => {
//     console.log("image clicked");
//     let imageApiURL = "https://bing-image-search1.p.rapidapi.com/images/search";
//     let headers = {
//       'x-rapidapi-key': '3fe9872e94mshf148b0071798372p1b8684jsndae02e4e3799',
//       'x-rapidapi-host': 'bing-image-search1.p.rapidapi.com'
//     }
//     let searchString = encodeURIComponent(this.state.searchText);
//     if (this.state.searchText) {
//       saveToMongo(this.state.searchText);
//       fetch(imageApiURL+"?q="+searchString, {
//         headers,
//         method: 'GET'
//       })
//       .then(res => res.json())
//       .then(jsonResponse => {
//         // console.log(jsonResponse)
//         let resultsId = Math.random();
//         let resultsKey = "results" + resultsId;
//         let resultType = "image"
//         if(this.state.searchText) {
//           let dataObject = {
//             searchText: this.state.searchText,
//             currPage: 1,
//             resultsKey: resultsKey,
//             resultsId: resultsId,
//             resultsType: resultType
//           };
//           console.log("Image stored in ===> ", resultsId)
//           dataObject[resultsKey] = jsonResponse.value ? jsonResponse.value : null;
//           dataService.setData(dataObject);
//         }
//         let redirectObj = {
//           pathname: "/search",
//           search:
//             "?searchText=" +
//             encodeURIComponent(this.state.searchText) +
//             "?resultsId=" +
//             encodeURIComponent(resultsId) +
//             "?currPage=" +
//             encodeURIComponent(1),
//         };
//         browserHistory.push(redirectObj);
//         window.location.reload(false);
//       })
//     }
//   }

//   newssearch = () => {
//     console.log("news clicked");
//     let newsApiURL = "https://bing-news-search1.p.rapidapi.com/news/search";
//     let headers = {
//       'x-bingapis-sdk': 'true',
//       'x-rapidapi-key': '3fe9872e94mshf148b0071798372p1b8684jsndae02e4e3799',
//       'x-rapidapi-host': 'bing-news-search1.p.rapidapi.com'
//     }
//     let searchString = encodeURIComponent(this.state.searchText);
//     if (this.state.searchText) {
//       saveToMongo(this.state.searchText);
//       fetch(newsApiURL+"?q="+searchString+"&safeSearch=Off&textFormat=Raw&freshness=Day", {
//         headers,
//         method: 'GET'
//       })
//       .then(res => res.json())
//       .then(jsonResponse => {
//         // console.log(jsonResponse)
//         let resultsId = Math.random();
//         let resultsKey = "results" + resultsId;
//         let resultType = "news"
//         if(this.state.searchText) {
//           let dataObject = {
//             searchText: this.state.searchText,
//             currPage: 1,
//             resultsKey: resultsKey,
//             resultsId: resultsId,
//             resultsType: resultType
//           };
//           console.log("News stored in ===> ", resultsId)
//           dataObject[resultsKey] = jsonResponse.value ? jsonResponse.value : null;
//           dataService.setData(dataObject);
//         }
//         let redirectObj = {
//           pathname: "/search",
//           search:
//             "?searchText=" +
//             encodeURIComponent(this.state.searchText) +
//             "?resultsId=" +
//             encodeURIComponent(resultsId) +
//             "?currPage=" +
//             encodeURIComponent(1),
//         };
//         browserHistory.push(redirectObj);
//         window.location.reload(false);
//       })
//     }
//   }

  setInput(data){
    this.setState({
      searchText: data,
    //   showSuggestions: false
    }, () => {
        this.webSearch(1)
    })
  }

  render() {
    let suggestions = "", display = ""
    // console.log("Searching for  ===> ", this.state.searchText)
    if(this.state.showSuggestions) {
        suggestions = this.state.filteredSuggestions.map(item => {
            return <ul style={{fontSize: 16, fontWeight: 'bold', color: '#33333d', cursor: 'pointer'}} onClick={() => {this.setInput(item.query)}}><SearchIcon style={{marginRight: 12}}/>{item.displayText}</ul>
        })
        
        display = <div style={{position: 'absolute', backgroundColor: '#fff', width: '100%', overflowY: 'none', boxShadow: "5px 10px 18px #888888", borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', paddingTop: 20, zIndex: 11111}}>{suggestions}</div>
    }

    // // console.log("Suggestions are => ", this.state.filteredSuggestions)
    // let button;
    // if (browserHistory.getCurrentLocation().pathname === "/") {
    //   button = (
    //     <div>
    //     <button type="button" className="s-button" onClick={this.search}>
    //       Web Search
    //     </button>
    //     <button type="button" className="s-button" onClick={this.imagesearch}>
    //     Image Search
    //   </button>
    //   <button type="button" className="s-button" onClick={this.newssearch}>
    //     News Search
    //   </button>
    //   </div>
    //   );
    // } else {
    //   button = null;
    // }
    return (
        <Grid item lg={6} className="search-bar">
          <FormControl type="search" fullWidth={true} onSubmit={this.search} >
            <Input
              placeholder="Type anything here"
              value={this.state.searchText ? this.state.searchText : ""}
              type="search"
              id="searchBar"
              onKeyPress={this.handleKeyPress}
              onChange={this.handleTextChange}
              autoComplete={'off'}
              onFocus={() => this.fetchSuggestions(this.state.searchText)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => this.webSearch(1)}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
            <div>
            {display}
            </div>
          </FormControl>
        </Grid>
    );
  }
}

export default SearchBar;

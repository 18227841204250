import React from 'react';
import ReactDOM from 'react-dom';
import Home from './Home';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Search from './Search'
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
	<Router>
		<Switch>
			<Route exact path='/'>
				<Home />
			</Route>
			<Route exact path='/search'>
				<Search />
			</Route>
    	</Switch>
  </Router>,
  document.getElementById('root')
);